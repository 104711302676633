import React from "react"
import { graphql } from "gatsby"
import { FaUserCircle, FaUserGraduate,
  FaUserTie, FaUserNinja, FaEnvelope, FaGithub,
  FaYoutube, FaInstagram, FaFacebook, FaLinkedin,
  FaTwitter
} from "react-icons/fa"
import { MdTranslate } from "react-icons/md";
import { BsFillChatDotsFill } from "react-icons/bs"
import ReactCountryFlag from "react-country-flag"
import cx from 'classnames'

import useTranslations from "../components/useTranslations"
import MeImage from "../components/meImage"
import SEO from "../components/seo"
import ContactForm from "../components/forms/contactForm"
import styles from './resume.module.css'

const ResumePage = ({ data: { site } }) => {
  const t = useTranslations();

  return (
    <article className={styles.gridContainer}>
      <SEO title="Resume" />
      <section id="basicSection">
        <FaUserCircle className={styles.sectionIcon} />
        <MeImage />
        <h2 className={styles.fullNameHeader}>{site.siteMetadata.author}</h2>
        <p>Honduran <span aria-label="Honduras flag" role="img">🇭🇳</span></p>
        <p>{t.profession} <span aria-label="Laptop icon" role="img">💻</span></p>
        <p>Amateur Linguist <MdTranslate/></p>
      </section>
      <section id="workSection">
        <FaUserTie className={styles.sectionIcon} />
        <h3>Work</h3>
        <div>
          <h4>Number8 LLC</h4>
          <p>{t.devConsultant}</p>
          <p>{t.fromTemporal} 2018 {t.untilTemporal} {t.today}</p>
        </div>
        <div>
          <h4>Caracol Knits S.A.</h4>
          <p>{t.seniorDeveloper}</p>
          <p>{t.fromTemporal} 2014 {t.untilTemporal} 2018</p>
        </div>
      </section>
      
      <section id="educationSection">
        <FaUserGraduate className={styles.sectionIcon} />
        <h3>Education</h3>
        <h4>Universidad Tecnológica Centroamericana</h4>
        <p>{t.underdegree}</p>
        <p>San Pedro Sula, Honduras</p>
      </section>
      <section id="technicalSkillsSection" className={styles.tagsSection}>
        <FaUserNinja className={styles.sectionIcon} />
        <h3>Technical Skills</h3>
        <ul>
          <li>
            .Net Framework
          </li>
          <li>
            WinForms
          </li>
          <li>
            React
          </li>          
          <li>
            Angular
          </li>          
          <li>
            C#
          </li>          
          <li>
            Javascript
          </li>          
          <li>
            .Net Framework
          </li>
          <li>
            SQL Server
          </li>
          <li>
            .Net Framework
          </li>
        </ul>
      </section>

      <section id="optionContainerSection">
        <button>Show the important stuff</button>
      </section>

      <section id="languagesSection">
        <MdTranslate className={styles.sectionIcon} />
        <h3>Language collection</h3>
        <div>
          <p><ReactCountryFlag
                className={styles.emojiFlag}
                countryCode="ES"
                aria-label="Spain flag"
                svg
                cdnUrl="https://cdn.jsdelivr.net/npm/flag-icon-css@3.4.6/flags/4x3/"
                cdnSuffix="svg"
                title="Español"
            /> <strong>Español</strong> <span>{t.spanishShortDescription}</span></p>
        </div>

        <div>
          <p><ReactCountryFlag
                className={styles.emojiFlag}
                countryCode="GB"
                aria-label="Great Britan flag"
                title="English"
            /> <strong>English</strong> <span>{t.englishShortDescription}</span></p>
        </div>
        
        <div>
          <p><ReactCountryFlag
                className={styles.emojiFlag}
                countryCode="PT"
                aria-label="Portugal flag"
                title="Português"
            /> <strong>Português</strong> <span>{t.portugueseShortDescription}</span></p>
        </div>
        
        <div>
          <p><ReactCountryFlag
                className={styles.emojiFlag}
                countryCode="DE"
                aria-label="Germany flag"
                title="Deutsch"
            /> <strong>Deutsch</strong> <span>{t.germanShortDescription}</span></p>
        </div>
      </section>
      <section className={cx(styles.tagsSection, styles.socialSection)}>
        <BsFillChatDotsFill className={styles.sectionIcon} />
        <h3>Social Media</h3>
        <ul>
          <li>
            <a href="https://github.com/jcespinoza"><FaGithub/> Github</a>
          </li>
          <li>
            <a href="https://linkedin.com/in/jcespinozah"><FaLinkedin/> LinkedIn</a>
          </li>
          <li>
            <a href="https://fb.com/jcespinoza15"><FaFacebook/> Facebook</a>
          </li>
          <li>
            <a href="https://twitter.com/jcespinozahz"><FaTwitter/> Twitter</a>
          </li>         
          <li>
            <a href="https://www.instagram.com/jcespinoza15/"><FaInstagram/> Instagram</a>
          </li>           
          <li>
            <a href="https://www.youtube.com/user/JayCEspinoza"><FaYoutube/> YouTube</a>
          </li>
          <li>
            <strike title="Of course I'm not in TikTok">TikTok</strike>
          </li>
        </ul>
      </section>
      <section className={styles.actualContactFormSection}>
        <FaEnvelope className={styles.sectionIcon} />
        <h3>Send a Message</h3>
        <ContactForm translations={t}/>
      </section>
    </article>
  )
}

export const data = graphql`
{
  site {
    siteMetadata {
      author
    }
  }
}`

export default ResumePage;
